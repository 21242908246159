<template>
	<div :class="['header', className('header-')]">
		<div class="navbar">
			<div class="left" @click="$router.push({ name: 'Toggle' })">
				<div class="title">{{ districeTitle }}</div>
			</div>
			<div class="right" @click="onClickSystemNotice">
				<van-badge :content="unReadTotal || ''" color="#FF6969">
					<div class="message"><img :src="require('@/assets/img/icon1.png')" /></div>
				</van-badge>
			</div>
		</div>
		<div class="banner">
			<van-swipe :autoplay="3000" :initial-swipe="initialSwipe" @change="swiperOnChange"
				:indicator-color="$store.state.district.type == 1 ? '#3377ff' : '#FF6969'">
				<van-swipe-item v-for="(vo, key) in bannerList">
					<img :src="vo.picture" @click="onClickBanner(key)" />
				</van-swipe-item>
			</van-swipe>
		</div>
		<div class="browse" v-if="isDistrict()">
			<div class="browse-item-top">
				今日浏览量：{{ weekCount }}
			</div>
			<div class="browse-item-bottom">
				本月浏览量：{{ totalCount }}
			</div>
		</div>
	</div>
</template>

<script>
import AdApi from '@/api/ad';
import IndexApi from '@/api/index';
import UtilsHelper from '@/vendor/utils';
import DistrictHelper from '@/vendor/district';
import indexTotal from "@/api/index"

export default {
	inject: ['className', 'isDistrict'],
	data() {
		return {
			initialSwipe: 0,
			_initialSwipe: 0,
			bannerList: [],
			bannerGroupId: UtilsHelper.getCurrentDistrictConfig().ad.banner,
			districeTitle: DistrictHelper.getDistrictName(),
			unReadTotal: "",
			weekCount: "",
			totalCount: "",
			user:""
		};
	},
	activated() {
		this.initialSwipe = this._initialSwipe;
		this.getSystemNoticeTotal();
	},
	created() {
		this.getSystemNoticeTotal();
		this.getAdList();
		
		if (this.isDistrict()) {
			this.getViewCount();
		}
	},
	methods: {
		swiperOnChange(index) {
			this._initialSwipe = index;
		},
		getSystemNoticeTotal() {
			indexTotal.indexUnread()
				.then(result => {
					this.unReadTotal = result.data.unRead
					if (this.unReadTotal == 0) {
						this.unReadTotal = 0
					}
				}).catch(error => {
					console.log(error)
				})
		},
		getAdList() {
			AdApi.getList({
				group_id: this.bannerGroupId
			}).then(result => {
				this.bannerList = result.data;
			}).catch(error => {
				console.log(error);
			});
		},
		getViewCount() {
			IndexApi.getViewCount()
				.then(result => {
					this.weekCount = result.data.todayCount;
					this.totalCount = UtilsHelper.numberToString(result.data.totalCount);
				})
				.catch(error => {
					console.log(error);
				});
		},
		onClickSystemNotice() {
			this.unReadTotal = 0
			this.$router.push({
				name: 'SystemNotice'
			})
		},
		onClickBanner(index) {
			if (this.bannerList[index].redirect) {
				window.location.href = this.bannerList[index].redirect
			}
		}
	}
};
</script>

<style lang="less" scoped>
.header {
	padding: 0 10px;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: top center;
	position: relative;

	.browse {
		position: absolute;
		z-index: 100;
		top: 56px;
		right: 10px;

		.browse-item-top {
			padding: 2px 10px;
			color: #fff;
			background-color: rgba(0, 0, 0, .3);
			border-radius: 10px 0 0 10px;
		}

		.browse-item-bottom {
			padding: 2px 10px;
			color: #fff;
			background-color: rgba(0, 0, 0, .3);
			border-radius: 10px 0 0 10px;
			margin-top: 10px;
		}
	}

	.navbar {
		height: 46px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.left {
			.title {
				height: 20px;
				padding: 0 3px;
				font-size: 16px;
				color: #fff;
				padding-left: 20px;
				padding-right: 10px;
				font-weight: bold;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 17px;
					height: 20px;
					background-image: url('~@/assets/img/icon2.png');
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100%;
				}

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%);
					width: 8px;
					height: 5px;
					background-image: url('~@/assets/img/icon3.png');
					background-position: center;
					background-repeat: no-repeat;
					background-size: 100%;
				}
			}
		}

		.right {
			padding-right: 9px;

			.message {
				width: 17px;
			}

			/deep/.van-badge {
				border-color: #ff6969;
			}
		}
	}

	.banner {
		border-radius: 6px;

		.van-swipe-item {
			width: 100%;
			height: 230px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		/deep/.van-swipe {
			.van-swipe__indicator {
				width: 8px;
				height: 8px;
				opacity: 0.5;
			}

			.van-swipe__indicator--active {
				width: 40px;
				opacity: 1;
				border-radius: 20px;
			}
		}

		img {
			border-radius: 6px;
			display: block;
		}
	}
}

/******************市级********************/
.header-city {
	background-image: url('~@/assets/img/header_bg_city.png');

	/deep/.van-swipe__indicator {
		background-color: #3377ff;
	}
}

/******************县级********************/
.header-district {
	background-image: url('~@/assets/img/header_bg_district.png');

	.van-swipe__indicator {
		background-color: #ff6969;
	}
}
</style>
