<template>
	<div class="nav">
		<div class="nav-city" v-if="$store.state.district.type == 1">
			<van-swipe :initial-swipe="initialSwipe" @change="swiperOnChange" indicator-color="#3377ff" ref="swiper">
				<van-swipe-item>
					<div class="nav-card">
						<div class="nav-item" @click="$router.push({ name: 'About' })">
							<img class="nav-icon" :src="require('@/assets/img/test3.png')" />
							<div class="nav-text">协会介绍</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'News' })">
							<img class="nav-icon" :src="require('@/assets/img/test4.png')" />
							<div class="nav-text">协会动态</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Group' })">
							<img class="nav-icon" :src="require('@/assets/img/test5.png')" />
							<div class="nav-text">队伍管理</div>
						</div>
						<!-- <div class="nav-item" @click="$router.push({ name: 'Rank' })">
							<img class="nav-icon" :src="require('@/assets/img/test6.png')" />
							<div class="nav-text">协会排行</div>
						</div> -->
						<div class="nav-item" @click="$router.push({ name: 'Dedication' })">
							<img class="nav-icon" :src="require('@/assets/img/test6.png')" />
							<div class="nav-text">奉献榜</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Redeem' })">
							<img class="nav-icon" :src="require('@/assets/img/test14.png')" />
							<div class="nav-text">爱心超市</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'SignIn',params:{type: 1} })">
							<img class="nav-icon" :src="require('@/assets/img/test8.png')" />
							<div class="nav-text">活动报名</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Business' })">
							<img class="nav-icon" :src="require('@/assets/img/test15.png')" />
							<div class="nav-text">义警商圈</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Company' })">
							<img class="nav-icon" :src="require('@/assets/img/test10.png')" />
							<div class="nav-text">爱心企业</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'ClueReportInfo' })">
							<img class="nav-icon" :src="require('@/assets/img/test21.png')" />
							<div class="nav-text">反映问题</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Supervision' })">
							<img class="nav-icon" :src="require('@/assets/img/test20.png')" />
							<div class="nav-text">义警监督</div>
						</div>
					</div>
				</van-swipe-item>
				<van-swipe-item>
					<div class="nav-card">
						<!-- 	<div class="nav-item" @click="$router.push({ name: 'SignIn',params:{type: 0} })">
							<img class="nav-icon" :src="require('@/assets/img/test9.png')" />
							<div class="nav-text">活动打卡</div>
						</div> -->
						<div class="nav-item" @click="$router.push({ name: 'Study' })">
							<img class="nav-icon" :src="require('@/assets/img/test11.png')" />
							<div class="nav-text">线上培训</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Telecast'})">
							<img class="nav-icon" :src="require('@/assets/img/test22.png')" />
							<div class="nav-text">爱心直播</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Task' })">
							<img class="nav-icon" :src="require('@/assets/img/test13.png')" />
							<div class="nav-text">任务中心</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Commodity' })">
							<!-- <div class="nav-item" @click="onClick"> -->
							<img class="nav-icon" :src="require('@/assets/img/test12.png')" />
							<div class="nav-text">义警商城</div>
						</div>
						<!-- <div class="nav-item" @click="$router.push({ name: 'Transfer' })">
							<img class="nav-icon" :src="require('@/assets/img/test7.png')" />
							<div class="nav-text">爱心传递</div>
						</div> -->
						<div class="nav-item" @click="$router.push({ name: 'newJob' })">
							<img class="nav-icon" :src="require('@/assets/img/test30.png')" />
							<div class="nav-text">公示</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Suggest' })">
							<img class="nav-icon" :src="require('@/assets/img/test17.png')" />
							<div class="nav-text">意见建议</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'ArticleTalent' })">
							<img class="nav-icon" :src="require('@/assets/img/test18.png')" />
							<div class="nav-text">人才库</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'ArticleHonor' })">
							<img class="nav-icon" :src="require('@/assets/img/test19.png')" />
							<div class="nav-text">荣誉榜</div>
						</div>
						<div class="nav-item"
							@click="$router.push({ name: 'Notice',params:{id: storageDistrictId,name: name}})">
							<img class="nav-icon" :src="require('@/assets/img/test23.png')" />
							<div class="nav-text">寻人启事</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Purchase' })">
							<img class="nav-icon" :src="require('@/assets/img/test25.png')" />
							<div class="nav-text">服装采购</div>
						</div>
					</div>
				</van-swipe-item>
				<template #indicator>
					<div class="indicator-box">
						<div :class="['box-item',{'active' : active == 0}]" @click="onPrev(0)"></div>
						<div :class="['box-item',{'active' : active == 1}]" @click="onNext(1)"></div>
						<!-- <div :class="['box-item',{'active' : active == 2}]" @click="onNext(2)"></div> -->
					</div>
				</template>
			</van-swipe>
		</div>
		<div class="nav-district" v-if="$store.state.district.type == 2">
			<van-swipe :initial-swipe="initialSwipe" @change="swiperOnChange" indicator-color="#FF6969" ref="swiper">
				<van-swipe-item>
					<div class="nav-card">
						<div class="nav-item" @click="$router.push({ name: 'About' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon1.png')" />
							<div class="nav-text">协会介绍</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'News' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon2.png')" />
							<div class="nav-text">协会动态</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Group' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon3.png')" />
							<div class="nav-text">队伍管理</div>
						</div>
						<!-- <div class="nav-item" @click="$router.push({ name: 'Rank' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon4.png')" />
							<div class="nav-text">协会排行</div>
						</div> -->
						<!-- <div class="nav-item" @click="$router.push({ name: 'Dedication' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon4.png')" />
							<div class="nav-text">奉献榜</div>
						</div> -->
						<div class="nav-item" @click="$router.push({ name: 'ArticleHonor' })">
							<img class="nav-icon" :src="require('@/assets/img/test32.png')" />
							<div class="nav-text">荣誉榜</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Redeem' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon11.png')" />
							<div class="nav-text">爱心超市</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'SignIn',params:{type: 1} })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon10.png')" />
							<div class="nav-text">活动报名</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Business' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon13.png')" />
							<div class="nav-text">义警商圈</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'DistrictRank' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon8.png')" />
							<div class="nav-text">奉献榜</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'ClueReportInfo' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon17.png')" />
							<div class="nav-text">反映问题</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Supervision' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon16.png')" />
							<div class="nav-text">义警监督</div>
						</div>
					</div>
				</van-swipe-item>
				<van-swipe-item>
					<div class="nav-card">
						<div class="nav-item" @click="$router.push({ name: 'Study' })">
							<img class="nav-icon" :src="require('@/assets/img/s11.png')" />
							<div class="nav-text">线上培训</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Company' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon6.png')" />
							<div class="nav-text">爱心企业</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Task' })">
							<img class="nav-icon" :src="require('@/assets/img/s12.png')" />
							<div class="nav-text">任务中心</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Telecast'})">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon18.png')" />
							<div class="nav-text">爱心直播</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Commodity' })">
							<!-- <div class="nav-item" @click="onClick"> -->
							<img class="nav-icon" :src="require('@/assets/img/test213.png')" />
							<div class="nav-text">义警商城</div>
						</div>
						<!-- <div class="nav-item" @click="$router.push({ name: 'Transfer' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon15.png')" />
							<div class="nav-text">爱心传递</div>
						</div> -->
						<!-- <div class="nav-item" @click="$router.push({ name: 'newJob' })">
							<img class="nav-icon" :src="require('@/assets/img/test31.png')" />
							<div class="nav-text">职衔公示</div>
						</div> -->
						<div class="nav-item" @click="$router.push({ name: 'Suggest' })">
							<img class="nav-icon" :src="require('@/assets/img/nav_district_icon14.png')" />
							<div class="nav-text">意见建议</div>
						</div>
						<div class="nav-item"
							@click="$router.push({ name: 'Notice',params:{id: storageDistrictId,name: name}})">
							<img class="nav-icon" :src="require('@/assets/img/test24.png')" />
							<div class="nav-text">寻人启事</div>
						</div>
						<div class="nav-item" @click="$router.push({ name: 'Purchase' })">
							<img class="nav-icon" :src="require('@/assets/img/test26.png')" />
							<div class="nav-text">服装采购</div>
						</div>
					</div>
				</van-swipe-item>
				<template #indicator>
					<div class="indicator-box">
						<div :class="['box-item',{'active' : active == 0}]" @click="onPrev(0)"></div>
						<div :class="['box-item',{'active' : active == 1}]" @click="onNext(1)"></div>
					</div>
				</template>
			</van-swipe>
		</div>
	</div>
</template>

<script>
	import Vant from '@/vendor/vant';
	import DistrictVendor from '@/vendor/district.js'
	export default {
		data() {
			return {
				initialSwipe: 0,
				_initialSwipe: 0,
				active: 0,
				storageDistrictId: '',
				name: ''
			}
		},
		created() {
			this.storageDistrictId = DistrictVendor.getDistrictId()
			this.name = DistrictVendor.getDistrictName()
		},
		activated() {
			this.initialSwipe = this._initialSwipe;
		},
		methods: {
			onPrev(index) {
				this.active = index
				this._initialSwipe = index
				this.initialSwipe = index
				// this.$refs.swiper.prev()
			},
			onNext(index) {
				this.active = index
				this._initialSwipe = index
				this.initialSwipe = index
				// this.$refs.swiper.next()
			},
			swiperOnChange(index) {
				this.active = index
				this._initialSwipe = index;
			},
			onClick() {
				Vant.Toast('正在开发中')
			}
		}
	};
</script>

<style lang="less" scoped>
	.nav {
		padding: 0 10px;

		/******************市级*******************/
		.nav-city {
			background-color: #fff;
			border-radius: 6px;
			box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);

			/deep/.van-swipe {
				.van-swipe__indicator {
					width: 20px;
					height: 3px;
					background-color: #aaa;
					opacity: 1;
					border-radius: 10px;
				}
			}

			.indicator-box {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				bottom: 10px;

				.box-item {
					width: 28px;
					height: 6px;
					background: #AAA;
					border-radius: 10px !important;
					margin: 0 5px;
				}

				.active {
					background: #3377FF;
				}
			}

			.nav-card {
				padding: 10px 0;
				box-sizing: border-box;

				&::after,
				&::before {
					content: '';
					display: table;
					clear: both;
				}

				.nav-item {
					float: left;
					width: 20%;
					padding: 14px 0;
					box-sizing: border-box;

					.nav-icon {
						display: block;
						width: 36px;
						height: 36px;
						margin: 0 auto;
					}

					.nav-text {
						font-size: 12px;
						padding-top: 8px;
						color: #000;
						text-align: center;
					}
				}
			}
		}

		/******************县级*******************/
		.nav-district {
			background-color: #fff;
			border-radius: 6px;
			box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);

			/deep/.van-swipe {
				.van-swipe__indicator {
					width: 20px;
					height: 3px;
					background-color: #aaa;
					opacity: 1;
					border-radius: 10px;
				}
			}

			.indicator-box {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				bottom: 10px;
				z-index: 20;

				.box-item {
					width: 28px;
					height: 6px;
					background: #AAA;
					border-radius: 10px !important;
					margin: 0 5px;
				}

				.active {
					background: rgb(255, 105, 105);
				}
			}

			.nav-card {
				padding: 4px 0 25px;

				&::after,
				&::before {
					content: '';
					display: table;
					clear: both;
				}

				.nav-item {
					float: left;
					width: 20%;
					padding-top: 14px;

					.nav-icon {
						display: block;
						width: 50px;
						margin: 0 auto;
					}

					.nav-text {
						font-size: 12px;
						padding-top: 8px;
						color: #000;
						text-align: center;
					}
				}
			}
		}
	}
</style>