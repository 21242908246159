<template>
	<div class="IndexBest">
		<div class="container">
			<!-- 最佳义警 -->
			<div class="container-item" v-if="memberList != ''">
				<w-title title="今日义警排行" :more="true" @click="$router.push({ name: 'BestPeopleList' })"></w-title>
				<div class="container-item-body">
					<div class="body-content" v-for="(item,index) in memberList">
						<div class="body-content-item item-center">
							<div class="body-content-left">
								<img :src="item.avatar" alt="">
								<div class="left-icon">{{index + 1}}</div>
							</div>
							<div class="body-content-right">
								<div class="content-right-title">{{item.name}}</div>
								<div class="content-right-text">{{item.district_txt}} {{item.level_text}}</div>
								<div class="content-right-text text-color">{{item.total_love_value}}爱心值</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 最佳协会 -->
			<div class="container-item" v-if="$store.state.district.type == 1">
				<w-title title="今日协会排行" :more="true" @click="$router.push({ name: 'BestGroupList' })"></w-title>
				<div class="container-item-body">
					<div class="body-content" v-for="(item,index) in orgList" v-if="index < 3">
						<div class="body-content-item">
							<div class="body-content-left left-radius">
								<img :src="item.image" alt="">
								<div class="left-icon">{{index + 1}}</div>
							</div>
							<div class="body-content-right">
								<div class="content-right-title">{{item.title}}{{item.title == '上饶市' ? '平安义警协会':'平安义警分会'}}</div>
								<div class="content-right-text">参加活动人次：{{item.activity_register}}次</div>
								<div class="content-right-text">今日活动数量：{{item.activity_num}}个</div>
								<div class="content-right-text text-color">人均{{item.per}}爱心值</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 最佳队伍 -->
			<div class="container-item" v-if="teamList != ''">
				<w-title title="今日队伍排行" :more="true" @click="$router.push({ name: 'BestTeamList' })"></w-title>
				<div class="container-item-body">
					<div class="body-content" v-for="(item,index) in teamList">
						<div class="body-content-item item-center">
							<div class="body-content-left left-team">
								<img :src="item.image" alt="">
								<div class="left-icon">{{index + 1}}</div>
							</div>
							<div class="body-content-right">
								<div class="content-right-title">{{item.title}}</div>
								<div class="content-right-text">{{item.district_name}}{{item.district_name == '上饶市' ? '平安义警协会':'平安义警分会'}}</div>
								<div class="content-right-text text-color">人均{{item.per}}爱心值</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WTitle from '@/components/Title';
	import Best from '@/api/best';
	
	export default {
		data() {
			return {
				memberList: [],
				orgList: [],
				teamList: [],
				limit: 3
			}
		},
		created() {
			this.getBestMember()
			this.getBestOrg()
			this.getBestTeam()
		},
		methods: {
			/**
			 * 最佳义警列表
			 */
			getBestMember(){
				Best.bestMember({limit: this.limit})
					.then(result => {
						this.memberList = result.data
					}, error => {
						console.log(error)
					})
			},
			/**
			 * 今日最佳协会
			 */
			getBestOrg(){
				Best.bestOrg({limit: this.limit})
					.then(result => {
						this.orgList = result.data
					}, error => {
						console.log(error)
					})
			},
			/**
			 * 今日最佳队伍
			 */
			getBestTeam(){
				Best.bestTeam({limit: this.limit})
					.then(result => {
						this.teamList = result.data
					}, error => {
						console.log(error)
					})
			}
		},
		components: {
			WTitle
		}
	}
</script>

<style scoped lang="less">
	.IndexBest {
		.container {
			.container-item {
				margin: 15px 10px 0;
				box-sizing: border-box;
				overflow: hidden;

				.container-item-header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 10px;

					.header-title {
						color: #37F;
						font-size: 18px;
						font-weight: bold;
						line-height: 30px;
						position: relative;
						padding-left: 8px;
						box-sizing: border-box;

						&::before {
							content: '';
							position: absolute;
							left: 0;
							top: 50%;
							width: 3px;
							height: 20px;
							transform: translateY(-50%);
							border-radius: 20px;
							background: #37F;
						}
					}
					
					.header-button {
						display: flex;
						align-items: center;

						.header-button-text {
							color: #777;
							font-size: 14px;
							line-height: 21px;
							margin-right: 2px;
						}

						.header-button-icon {
							width: 14px;
							height: 14px;
							
							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}
				}
				
				.container-item-body {
					margin-top: 10px;
					width: 355px;
					padding: 14px 10px 14px 10px;
					border-radius: 8px;
					background: #FFF;
					box-shadow: 0px 0px 8px 0px rgba(51, 119, 255, 0.10);
					box-sizing: border-box;
					overflow-y: auto;
					white-space: nowrap;

					.body-content {
						display: inline-block;
						margin-right: 12px;

						&.body-content:last-child {
							margin-right: 0;
						}

						.body-content-item {
							display: flex;
							
							&.item-center{
								align-items: center;
							}
							
							.body-content-left {
								width: 80px;
								height: 80px;
								position: relative;
								display: inline-block;
								
								&.left-team{
									width: 106px;
									height: 80px;
								}
								
								&.left-radius{
									width: 106px;
									height: 101px;
									
									img {
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}
								
								img {
									width: 100%;
									height: 100%;
									border-radius: 4px;
									object-fit: cover;
								}

								.left-icon {
									position: absolute;
									top: 0;
									left: 0;
									width: 20px;
									height: 20px;
									color: #FFF;
									font-size: 14px;
									text-align: center;
									line-height: 20px;
									border-radius: 4px 0px;
									background: #FF6969;
									
									&.icon-radius{
										border-radius: 50%;
									}
								}
							}

							.body-content-right {
								display: inline-block;
								margin-left: 8px;

								.content-right-title {
									color: #222;
									font-size: 17px;
									line-height: 26px;
								}

								.content-right-text {
									color: #777;
									font-size: 14px;
									line-height: 21px;
									margin-top: 4px;

									&.text-color {
										color: #FF6969;
									}
								}
							}
						}
					}
				}
			}
		}
	}
</style>