<template>
	<div class="count">
		<div class="count-container" v-if="$store.state.district.type == 1">
			<div class="count-item count-item-vigilante">
				<div class="count-item-title">平安志愿者总数</div>
				<div class="count-item-number">{{ volunteer }}</div>
				<!-- <div class="count-item-number">19766</div> -->
			</div>
			<div class="count-item count-item-volunteer">
				<div class="count-item-title">义警总数</div>
				<div class="count-item-number">{{ vigilante }}</div>
				<!-- <div class="count-item-number">1084</div> -->
			</div>
		</div>
		<div class="ad-container">
			<van-swipe :autoplay="3000" indicator-color="#3377ff">
				<van-swipe-item v-for="vo in adList">
					<div class="ad-item"><img :src="vo.picture" @click="onPush(vo.redirect)" /></div>
				</van-swipe-item>
			</van-swipe>
		</div>
	</div>
</template>

<script>
import AdApi from '@/api/ad';
import IndexApi from '@/api/index';
import UtilsHelper from '@/vendor/utils';

export default {
	data() {
		return {
			vigilante: '',
			volunteer: '',
			adList: [],
			adGroupId: UtilsHelper.getCurrentDistrictConfig().ad.center
		};
	},
	created() {
		if (this.$store.state.district.type == 1) {
			IndexApi.getUserCount()
				.then(result => {
					this.vigilante = UtilsHelper.numberToString(result.data.total_yj);
					this.volunteer = UtilsHelper.numberToString(result.data.total_zyz);
				})
				.catch(error => {
					console.log(error);
				});

			AdApi.getList({
				group_id: this.adGroupId
			})
				.then(result => {
					this.adList = result.data;
				})
				.catch(error => {
					console.log(error);
				});
		}
	},
	methods: {
		// 图片链接跳转
		onPush(url) {
			if(url){
				window.location.href = url;
			}
		}
	}
};
</script>

<style lang="less" scoped>
.count {
	padding: 0 10px;

	.count-container {
		display: flex;
		padding: 10px 0;
		box-sizing: border-box;

		.count-item {
			height: 79px;
			border-radius: 8px;
			background-color: #fff;
			padding: 13px 20px;
			box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
			box-sizing: border-box;
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			flex: 1;

			&:first-child {
				margin-right: 10px;
			}

			&.count-item-vigilante {
				background-image: url('~@/assets/img/count_city_bg1.png');
			}

			&.count-item-volunteer {
				background-image: url('~@/assets/img/count_city_bg2.png');
			}

			.count-item-title {
				font-size: 16px;
				color: #666;
				line-height: 23px;
				margin-bottom: 1px;
			}

			.count-item-number {
				font-size: 24px;
				line-height: 29px;
				color: #3377ff;
				font-family: 'Bahnschrift';
			}
		}
	}

	.ad-container {
		

		/deep/.van-swipe {
			.van-swipe__indicator {
				width: 20px;
				height: 3px;
				background-color: #aaa;
				opacity: 1;
				border-radius: 10px;
			}
		}

		.ad-item {
			width: 100%;
			height: 100px;
			border-radius: 8px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
</style>
