<template>
	<div class="footer">
		<div class="footer-container" v-if="$store.state.district.type == 1">
			<div class="footer-total">{{ totalCount }}</div>
			<div class="footer-week">总浏览量（今日+{{ weekCount }}次）</div>
		</div>
	</div>
</template>

<script>
import IndexApi from '@/api/index';
import UtilsHelper from '@/vendor/utils';
export default {
	inject: ['className', 'isCity'],
	data() {
		return {
			weekCount: '',
			totalCount: '',
		};
	},
	created() {
		if (this.isCity()) {
			IndexApi.getViewCount()
				.then(result => {
					this.weekCount = result.data.todayCount;
					this.totalCount = UtilsHelper.numberToString(result.data.totalCount);
				})
				.catch(error => {
					console.log(error);
				});
		}
	}
};
</script>

<style lang="less" scoped>
.footer {
	padding: 15px 10px;

	.footer-container {
		padding: 10px 0;
		text-align: center;
		background-color: #fff;
		background-image: url('~@/assets/img/footer_bg_city.png');
		background-position: center;
		background-size: cover;
		box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
		border-radius: 8px;

		.footer-total {
			color: #4292f6;
			font-size: 48px;
			line-height: 56px;
			font-family: Bahnschrift;
			padding-bottom: 5px;
		}

		.footer-week {
			color: #666;
			font-size: 16px;
			line-height: 24px;
		}
	}
}
</style>
