<template>
	<div class="daily">
		<van-popup v-model="show" closeable close-icon="close"  >
			<div class="dailys-box">
				<div class="dailys-image"><img src="../../../assets/img/mrqd.png"></div>
				<div class="dailys-body">
					<div class="dailys-body-middle">
						<div class="body-middle-left">+</div>
						<div class="body-middle-text">{{love}}</div>
						<div class="body-middle-right">爱心</div>
					</div>
					<div class="dailys-btn-group" @click="onConfirm">
						确定
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				love:""
			}
		},
		watch: {
			isShow(newVal) {
				this.show = newVal
			},
			loveValue(newVal){
				this.love = newVal
			}
		},
		created() {
			this.show = this.isShow
			this.love = this.loveValue
		},
		props: {
			isShow: {
				default: false,
			},
			loveValue: {
				default: 0
			},
		},
		methods: {
			onConfirm() {
				this.$emit("onConfirm")
			},
		}
	}
</script>

<style scoped lang="less">
	.daily {

		/deep/.van-popup {
			background: transparent;
		}

		/deep/.van-popup__close-icon--top-right {
			top: 370px;
			left: 113px;
		}

		.dailys-box {
			position: relative;

			.dailys-image {
				width: 245px;
				height: 367px;
				padding-bottom: 50px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.dailys-body {
				text-align: center;
				.dailys-body-head {
					font-weight: bold;
					font-size: 30px;
					line-height: 45px;
					text-align: center;
					color: #FFE4AF;
					text-shadow: 0px 0px 20px rgba(253, 74, 0, 0.5);
				}

				.dailys-body-middle {
					color: #F43E3D;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 32px;
					margin-bottom: 5px;
					position: absolute;
					top: 40px;
					left: 84px;
					
					.body-middle-left {
						font-weight: bold;
						font-size: 24px;
						line-height: 35px;
					}

					.body-middle-text {
						font-size: 72px;
						line-height: 86px;
					}

					.body-middle-right {
						font-size: 18px;
						line-height: 26px;
						padding-top: 35px;
					}
				}

				.dailys-btn-group {
					width: 136px;
					height: 30px;
					background: #FFE390;
					box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
					border-radius: 100px;
					padding: 5px 22px;
					font-size: 14px;
					line-height: 20px;
					color: #FF1952;
					box-sizing: border-box;
					position: absolute;
					left: 55px;
					bottom: 80px;
				}
			}

		}
	}
</style>
