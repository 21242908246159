<template>
	<div class="progress">
		<div class="progress-bar" style="width: 80%;"></div>
	</div>
</template>

<script>
export default {
	
};
</script>

<style lang="less" scoped>
	.progress {
		width: 100%;
		height: 6px;
		background-color: #f2f2f2;
		border-radius: 4px;
		
		.progress-bar {
			height: 6px;
			background-color: #FF6969;
			border-radius: 4px;
		}
	}
</style>
