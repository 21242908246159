<template>
	<div class="love" v-if="$store.state.district.type == 1">
		<w-tab :active="active" :more="true" :data="navList" @click="onClick" @click-more="clickMore"></w-tab>
		<div class="love-content">
			<div class="transfer" v-show="active == 0">
				<div class="transfer-item" v-for="vo in fundraiserList"
					@click="$router.push({ name: 'TransferDetails', params: { id: vo.id } })">
					<div class="transfer-item-title">{{ vo.title }}</div>
					<div class="transfer-item-progress">
						<div class="middle">
							<div class="p-line">
								<div class="p-done" :style="{
									width: progressWidth(vo.donate_integral, vo.need_integral) + '%'
								}"></div>
							</div>
						</div>
					</div>
					<div class="transfer-item-attr">
						<div class="transfer-item-attr-left">
							<div class="transfer-item-attr-title">已筹/爱心积分</div>
							<div class="transfer-item-attr-number">{{ vo.donate_integral == null ? 0 :
									vo.donate_integral
							}}</div>
						</div>
						<div class="transfer-item-attr-right">
							<div class="transfer-item-attr-title">目标/爱心积分</div>
							<div class="transfer-item-attr-number">{{ vo.need_integral }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="company" v-show="active == 1">
				<div class="company-item" v-for="item in companiesList">
					<div class="company-item-left"><img :src="item.image" /></div>
					<div class="company-item-right">
						<div class="company-item-title">{{ item.name }}</div>
						<div class="company-item-integral">积分：{{ item.integral }}</div>
						<div class="company-item-address">地址：{{ item.address }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import WTab from '@/components/Tab';
import WProgress from '@/components/Progress';
import indexApi from '@/api/index.js';
export default {
	data() {
		return {
			active: 0,
			navList: [
				{ title: '爱心传递' },
				{ title: '爱心企业' }
			],
			companiesList: [],
			fundraiserList: [],
		};
	},
	created() {
		this.getDonateList();
		this.getCompanyList();
	},
	activated() {
		this.getDonateList();
	},
	methods: {
		getDonateList() {
			indexApi.donateIndex()
				.then(result => {
					this.fundraiserList = result.data;
				})
				.catch(error => {
					console.log(error);
				});
		},
		getCompanyList() {
			indexApi.companies({
				page: 1,
				limit: 3
			}).then(result => {
				this.companiesList = result.data.list;
			}).catch(error => {
				console.log(error);
			});
		},
		progressWidth(current, total) {
			let progress = parseInt((current / total) * 100);
			return progress < 100 ? progress : 100;
		},
		clickMore() {
			if (this.active == 0) {
				this.$router.push({ name: "Transfer" })
			} else {
				this.$router.push({ name: "Company" })
			}
		},
		onClick(key) {
			this.active = key;
		}
	},
	components: {
		WTab,
		WProgress
	}
};
</script>

<style lang="less" scoped>
.love {
	padding: 15px 10px 0;

	.love-content {
		padding-top: 7px;
	}

	.transfer {
		.transfer-item {
			background-color: #fff;
			padding: 8px 10px;
			border-radius: 8px;
			margin-bottom: 8px;
			box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);

			&:last-child {
				margin-bottom: 0;
			}

			.transfer-item-title {
				font-size: 18px;
				line-height: 24px;
				color: #333;
				padding-bottom: 14px;
			}

			.transfer-item-attr {
				padding-top: 6px;
				display: flex;
				justify-content: space-between;

				.transfer-item-attr-title {
					font-size: 12px;
					line-height: 22px;
					color: #666;
				}

				.transfer-item-attr-number {
					color: #3377ff;
					font-size: 18px;
					line-height: 22px;
				}
			}
		}
	}

	.middle {
		.p-line {
			position: relative;
			z-index: 1;
			width: 100%;
			height: 6px;
			border-radius: 2px;
			background: #ddd;
		}

		.p-done {
			position: absolute;
			z-index: 2;
			height: 6px;
			border-radius: 4px;
			background: #A5C5FF;
			background-size: 30px 30px;
		}
	}

	.company {
		.company-item {
			padding: 10px;
			background-color: #fff;
			border-radius: 6px;
			display: flex;
			margin-bottom: 10px;
			box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);

			&:last-child {
				margin-bottom: 0;
			}

			.company-item-left {
				width: 122px;
				min-width: 122px;
				height: 90px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 6px;
					display: block;
				}
			}

			.company-item-right {
				padding-left: 10px;

				.company-item-title {
					width: 200px;
					min-width: 200px;
					font-size: 18px;
					line-height: 20px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.company-item-integral,
				.company-item-address {
					font-size: 14px;
					line-height: 20px;
					color: #666;
					padding-left: 20px;
					background-repeat: no-repeat;
					background-size: 16px;
				}

				.company-item-integral {
					margin: 5px 0;
					background-image: url('~@/assets/img/icon7.png');
					background-position: left center;
				}

				.company-item-address {
					background-image: url('~@/assets/img/icon8.png');
					background-position: left 2px;
				}
			}
		}
	}
}
</style>
