import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 获取广告列表
const getList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/index/banners', params)
}

export default {
	getList
}
