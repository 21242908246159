<template>
	<div :class="['task', className('task-')]">
		<w-title title="每日任务" :more="true" @click="onClickMore"></w-title>
		<online-task-list :task-list="taskList"></online-task-list>
	</div>
</template>

<script>
import IndexApi from '@/api/index';
import WTitle from '@/components/Title';
import OnlineTaskList from '@/components/OnlineTaskList';

export default {
	inject: ['className'],
	data() {
		return {
			taskList: []
		};
	},
	created() {
		this.getTaskList();
	},
	activated() {
		this.getTaskList();
	},
	methods: {
		getTaskList() {
			IndexApi.getIndexTask()
				.then(result => {
					this.taskList = result.data;
				})
				.catch(error => {
					console.log(error);
				});
		},
		onClickMore() {
			this.$router.push({
				name: 'Task'
			});
		}
	},
	components: {
		WTitle,
		OnlineTaskList
	}
};
</script>

<style lang="less" scoped>
.task {
	padding: 15px 10px 0;

	.w-title {
		margin-bottom: 12px;
	}
}
</style>
