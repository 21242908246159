<template>
	<div :class="['attention', className('attention-')]">
		<van-popup v-model="isShow" :close-on-click-overlay="false">
			<div class="attention-box">
				<div class="attention-nav">
					平台上线啦
				</div>
				<div class="attention-nav-bottom"></div>
				<div class="attention-image">
					<img src="@/assets/img/gzgzh.jpg">
				</div>
				<div class="attention-body">
					<div class="attention-body-top">
						<div>长按扫码</div>
						<div>关注我们</div>
					</div>
					<div class="attention-body-middle">
						<div>微信号</div>
						<span></span>
						<div>sryjpazyz</div>
					</div>
					<div class="attention-body-bottom" @click="confirm">
						我已关注
					</div>
					<div class="attention-body-footer" @click="cancel">
						暂不关注
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
	inject: ['themeColor', 'className', 'districtType'],
	props: {
		isShow: {
			default: false,
		},
	},
	methods: {
		confirm() {
			this.$emit("confirm")
		},
		cancel() {
			this.$emit("cancel")
		}
	}
}
</script>

<style scoped lang="less">
.attention-city{
	.attention-box{
		.attention-nav-bottom{
			background: #3377FF;
		}
		.attention-body{
			.attention-body-middle{
				span{
					background: #3377FF;
				}
			}
			.attention-body-bottom{
				background: #3377FF;
			}
		}
	}
}
	
.attention-district{
	.attention-box{
		.attention-nav-bottom{
			background: #ff6969;
		}
		.attention-body{
			.attention-body-middle{
				span{
					background: #ff6969;
				}
			}
			.attention-body-bottom{
				background: #ff6969;
			}
		}
	}
}



.attention {

	/deep/.van-popup {
		background: transparent;
	}

	/deep/.van-popup__close-icon--top-right {
		top: 370px;
		left: 108px;
	}

	.attention-box {
		position: relative;
		width: 260px;
		height: 409px;
		background: #FFFFFF;
		border-radius: 6px;
		padding-bottom: 20px;

		.attention-nav {
			font-weight: 700;
			font-size: 24px;
			color: #222;
			padding-top: 30px;
			text-align: center;
		}

		.attention-nav-bottom {
			width: 39px;
			height: 4px;
			left: 111px;
			margin: 10px 0 16px 111px;
		}

		.attention-image {
			width: 150px;
			height: 150px;
			padding: 0 55px 5px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.attention-body {


			.attention-body-top {
				font-weight: 700;
				font-size: 18px;
				line-height: 26px;
				color: #777;
				padding: 0 54px;
				display: flex;
				justify-content: space-between;
			}

			.attention-body-middle {
				font-size: 14px;
				line-height: 26px;
				color: #222;
				padding: 0 66px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 20px;

				span {
					display: block;
					width: 1px;
					height: 15px;
				}
			}

			.attention-body-bottom {
				width: 190px;
				height: 40px;
				border-radius: 6px;
				margin-left: 35px;
				text-align: center;
				font-size: 16px;
				line-height: 40px;
				color: #FFF;
			}

			.attention-body-footer {
				font-size: 16px;
				line-height: 26px;
				text-align: center;
				color: #777;
				margin-top: 10px;
			}
		}

	}
}
</style>
