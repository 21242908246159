<template>
	<div class="activity">
		<template v-if="isCity()">
			<scroll-tab class="activity-tab-container" :scroll-x="true" :menu="districtList" :active="active" field="name" @click="onClick"></scroll-tab>
			<div class="city-activity-list">
				<activity-item v-for="vo in activityList" :activity="vo" @click="onClickActivityItem(vo)"></activity-item>
				<div class="activity-list-city-more" @click="$router.replace({ name: 'SignIn',params:{type: 1} })">查看全部活动</div>
			</div>
			<!-- <div class="city-activity-empty" v-else>
				<div class="activity-empty-title">请点击按钮跳转信州独立平台</div>
				<div class="activity-empty-button" @click="onJumpArea">前往信州</div>
			</div> -->
		</template>
		<template v-if="isDistrict()">
			<w-title title="活动报名" :more="true" @click="$router.replace({ name: 'SignIn',params:{type: 1} })"></w-title>
			<div class="district-activity-list">
				<activity-item v-for="vo in activityList" :activity="vo" @click="onClickActivityItem(vo)"></activity-item>
			</div>
		</template>
	</div>
</template>

<script>
import IndexApi from '@/api/index';
import DistrictApi from '@/api/district';
import DistrictStore from '@/vendor/district';
import VantVendor from '@/vendor/vant';
import WTitle from '@/components/Title';
import ScrollTab from '@/components/ScrollTab';
import ActivityItem from '@/components/ActivityItem';
import ActionVendor from "@/vendor/action"

export default {
	data() {
		return {
			active: 0,
			districtList: [],
			activityList: [],
			activity: ''
		};
	},
	inject: ['isCity', 'isDistrict'],
	created() {

		if (this.isCity()) {
			this.getDistrictList();
		}
		
		this.getActivityList(DistrictStore.getDistrictId());
	},
	methods: {
		getDistrictList() {
			DistrictApi.getAll()
				.then(result => {
					this.districtList = result.data.list;
					this.districtList.splice(1,1)
				})
				.catch(error => {
					console.log(error);
				})
		},
		getActivityList(districtId) {
			IndexApi.getIndexActivity({
				district_id: districtId
			}).then(result => {
				this.activityList = result.data;
			}).catch(error => {
				VantVendor.Toast.fail(error.msg);
			});
		},
		onClick(index) {
			this.active = index;
			this.getActivityList(this.districtList[index].id);
		},
		onClickActivityItem(data) {
			this.$router.push({
				name: 'ActivityDetail',
				params: {
					activityId: data.id
				}
			});
		},
		onJumpArea(){
			ActionVendor.jumpArea()
		}
	},
	components: {
		WTitle,
		ScrollTab,
		ActivityItem
	}
};
</script>

<style lang="less" scoped>
@import '~@/assets/less/style.less';

.activity {
	padding: 0 10px;
	padding-top: 15px;

	.activity-tab-container {
		height: 29px;

		/deep/.scroll-tab-item {
			padding-top: 0;
			font-size: 18px;
			line-height: 27px;
			padding-bottom: 2px;

			&.scroll-tab-item-active:after {
				bottom: 0;
			}
		}
	}

	.district-activity-list {
		margin-top: 10px;

		/deep/.activity-item {
			border-radius: 8px;
			margin-bottom: 10px;
		}
	}

	.city-activity-list {
		margin-top: 10px;
		padding: 10px;
		background-color: #fff;
		border-radius: 8px;

		/deep/.activity-item {
			padding-left: 0;
			padding-right: 0;

			&:first-child {
				padding-top: 0;
			}

			.border-top();

			&:first-child:after {
				display: none;
			}
		}

		.activity-list-city-more {
			width: 100%;
			height: 33px;
			line-height: 33px;
			text-align: center;
			background: #eef4ff;
			border-radius: 6px;
			color: #3377ff;
			font-size: 16px;
		}
	}
	
	.city-activity-empty{
		margin-top: 10px;
		padding: 10px;
		background-color: #fff;
		border-radius: 8px;
		box-sizing: border-box;
		text-align: center;
		
		.activity-empty-title{
			padding: 30px 0;
			color: #333;
			font-size: 16px;
			box-sizing: border-box;
		}
		.activity-empty-button{
			width: 100%;
			height: 33px;
			line-height: 33px;
			text-align: center;
			background: #eef4ff;
			border-radius: 6px;
			color: #3377ff;
			font-size: 16px;
		}
	}
}
</style>
