<template>
	<div class="duty">
		<div class="container">
			<div class="duty-city" v-if="$store.state.district.type == 1">
				<div class="duty-left">
					<img :src="require('@/assets/img/index_duty_city.png')" />
				</div>
				<div class="duty-right">
					<div class="duty-leader" v-if="leader1.name">带班领导：{{ leader1.name }}<!-- <span>电话：8269899</span> --></div>
					<div class="duty-leader" v-if="leader2.name">值班人员：{{ leader2.name }}</div>
				</div>
			</div>
			<div class="duty-district" v-if="$store.state.district.type == 2">
				<div class="duty-left">
					<img :src="require('@/assets/img/test256.png')" />
				</div>
				<div class="duty-right">
					<div class="duty-leader" v-if="leader1.name">带班领导：{{ leader1.name }}<!-- <span>电话：8269899</span> --></div>
					<div class="duty-leader" v-if="leader2.name">值班人员：{{ leader2.name }}</div>
				</div>
				<div class="duty-icon">
					<img src="@/assets/img/test257.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import IndexApi from '@/api/index';

export default {
	data() {
		return {
			leader1: '',
			leader2: ''
		};
	},
	created() {
		IndexApi.getTodayDuty()
			.then(result => {
				this.leader1 = result.data.person1;
				this.leader2 = result.data.person2;
			})
			.catch(error => {
				console.log(error);
			});
	}
};
</script>

<style lang="less" scoped>
.duty {
	margin: 10px;
	background-color: #fff;
	border-radius: 6px;

	/*********市级***********/
	.duty-city {
		padding: 10px;
		background: url('~@/assets/img/test208.png');
		background-size: 100%;
		display: flex;
		align-items: center;
		box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);

		.duty-left {
			width: 43px;
			padding-right: 10px;
			margin-right: 10px;
			border-right: 1px solid #f2f2f2;
		}
		
		.duty-leader {
			font-size: 14px;
			color: #333;
			line-height: 22px;
			
			span{
				margin-left: 10px;
			}
		}
	}

	/**********县级***************/
	.duty-district {
		height: 70px;
		padding: 10px;
		background-size: 100%;
		display: flex;
		align-items: center;
		box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
		position: relative;
		box-sizing: border-box;

		.duty-left {
			width: 43px;
			padding-right: 10px;
			margin-right: 10px;
			border-right: 1px solid #f2f2f2;
		}

		.duty-right {
			position: relative;
			z-index: 10;
			
			.duty-leader {
				font-size: 14px;
				color: #333;
				line-height: 22px;
				
				span{
					margin-left: 10px;
				}
				
				&:last-child {
					margin-right: 0;
				}
			}
		}
		
		.duty-icon{
			position: absolute;
			top: 0;
			right: 0;
			width: 49px;
			height: 70px;
			
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
</style>
