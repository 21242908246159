import { render, staticRenderFns } from "./IndexCount.vue?vue&type=template&id=201d87ef&scoped=true&"
import script from "./IndexCount.vue?vue&type=script&lang=js&"
export * from "./IndexCount.vue?vue&type=script&lang=js&"
import style0 from "./IndexCount.vue?vue&type=style&index=0&id=201d87ef&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201d87ef",
  null
  
)

export default component.exports