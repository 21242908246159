<template>
	<div class="doyinPop">
		<van-popup v-model="show" :close-on-click-overlay="false" closeable close-icon="close" >
			<div class="dailys-box">
				<div class="dailys-image"><img src="../../../assets/img/dygz.png"></div>
				<div class="dailys-body">
					<div class="dailys-body-middle">
					</div>
					<div class="dailys-btn-group" >
						<div class="dailys-btn-group-head-text">
							关注官方抖音号<br/>立即获得<span>10</span>个爱心值
						</div>
						<div class="dailys-btn-group-title" @click="$router.push({ name: 'InterestDoyin' })">立即关注</div> 
						<div class="dailys-btn-group-text" @click="onConfirm">不在提醒</div>
					</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
			}
		},
		watch: {
			isShow(newVal) {
				setTimeout(() => {
					this.show = newVal
				}, 1)
			},
		},
		created() {
			this.show = this.isShow
		},
		props: {
			isShow: {
				default: false,
			},
		},
		methods: {
			onConfirm() {
				this.$emit("onConfirm")
			},
		}
	}
</script>

<style scoped lang="less">
	.doyinPop {

		/deep/.van-popup {
			background: transparent;
		}

		/deep/.van-popup__close-icon--top-right {
			z-index: 1000;
		}

		.dailys-box {
			position: relative;

			.dailys-image {
				width: 315px;
				height: 440px;
				padding-bottom: 50px;
				
				img {
					width: 100%;
					height: 100%;
					border-radius: 8px;
					object-fit: cover;
				}
			}

			.dailys-body {
				text-align: center;
				.dailys-body-head {
					font-weight: bold;
					font-size: 30px;
					line-height: 45px;
					text-align: center;
					color: #FFE4AF;
					text-shadow: 0px 0px 20px rgba(253, 74, 0, 0.5);
				}

				.dailys-body-middle {
					color: #F43E3D;
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 32px;
					margin-bottom: 5px;
					position: absolute;
					top: 40px;
					left: 84px;
					
					.body-middle-left {
						font-weight: bold;
						font-size: 24px;
						line-height: 35px;
					}

					.body-middle-text {
						font-size: 72px;
						line-height: 86px;
					}

					.body-middle-right {
						font-size: 18px;
						line-height: 26px;
						padding-top: 35px;
					}
				}

				.dailys-btn-group {
					position: absolute;
					left: 40px;
					bottom: 60px;
					
					.dailys-btn-group-head-text{
						font-size: 14px;
						line-height: 20px;
						text-align: center;
						color: #FFF;
						margin-bottom: 32px;
						span{
							font-size: 16px;
							color: #FE2C55;
						}
					}
					.dailys-btn-group-title{
						width: 236px;
						height: 44px;
						background: #3377FF;
						box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
						border-radius: 100px;
						font-size: 16px;
						line-height: 44px;
						color: #FFF;
						text-align: center;
						box-sizing: border-box;
					}
					.dailys-btn-group-text{
						font-size: 14px;
						line-height: 26px;
						color: #FFFFFF;
						margin-top: 8px;
					}
				}
			}

		}
	}
</style>
