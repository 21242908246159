<template>
	<div class="news">
		<div class="container">
			<w-title title="新闻动态" :more="true" @click="$router.push({ name: 'News' })"></w-title>
			<news-list :news-list="newsList"></news-list>
		</div>
	</div>
</template>

<script>
import NewsApi from '@/api/news';
import WTitle from '@/components/Title';
import NewsList from '@/components/NewsList';
import UtilsHelper from '@/vendor/utils';

export default {
	data() {
		return {
			newsList: [],
			columnId: UtilsHelper.getCurrentDistrictConfig().column.news,
			paramsPage: 1,
			paramsLimit: 4
		};
	},
	created() {
		NewsApi.articleIndex({
			page: this.paramsPage,
			limit: this.paramsLimit,
			column_id: this.columnId
		}).then(result => {
			this.newsList = result.data;
		}).catch(error => {
			console.log(error);
		});
	},
	components: {
		WTitle,
		NewsList
	}
};
</script>

<style lang="less" scoped>
.news {
	padding-top: 15px;

	.container {
		padding: 0 10px;

		.news-list {
			margin-top: 10px;
			box-shadow: 0px 0px 8px rgba(51, 119, 255, 0.1);
		}
	}
}
</style>
