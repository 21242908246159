<template>
	<div class="index">
		<scroll-view class="index-scroll">
			<w-header></w-header>
			<w-duty></w-duty>
			<w-nav></w-nav>
			<w-count></w-count>
			<!-- <w-task></w-task> -->

			<w-best></w-best>

			<w-activity></w-activity>
			<!-- <w-love></w-love> -->
			<w-news></w-news>
			<w-footer></w-footer>
		</scroll-view>
		<w-tabbar></w-tabbar>
		<index-subscribe :isShow="isShowSubscribe" @confirm="isSubscribe" @cancel="isShowSubscribe = false" />
		<dailysign :loveValue="dailysignLove" :isShow="isDailysign" @onConfirm="isDailysign = false"></dailysign>
		<doyinPop :isShow="isDoyinPop" @onConfirm="onDoyinPopConfirm"></doyinPop>
		<!-- 商城商品弹窗 -->
		<ShopPop :shopPopImg="ShopPopInfo.thumb" :isShow="isShopPop" @onClose="isShopPop = !isShopPop"
			@onConfirm="onShopPopConfirm" @onCancel="isShopPop = !isShopPop"></ShopPop>
		<div class="index-float" @click="isShopPop = !isShopPop" v-if="shopPopType == 2">
			<img :src="ShopPopInfo.logo" alt="">
			<div class="index-float-hide" @click.stop="onClickhide">
				<van-icon name="clear" size="25" color="rgba(51, 51, 51, 0.35)" />
			</div>
		</div>
		
		<!-- 人寿保险 -->
		<Insurance :isShow="InsuranceShow" @onClose="InsuranceShow = !InsuranceShow" @onConfirm="onInsuranceConfirm" @onCancel="InsuranceShow = !InsuranceShow"></Insurance>
	</div>
</template>

<script>
	import VantVendor from '@/vendor/vant';
	import scrollMiXin from '@/mixins/scroll';
	import ScrollView from '@/components/ScrollView';
	import WTabbar from '@/components/Tabbar';
	import WBest from '@/views/index/components/IndexBest';
	import WNav from '@/views/index/components/IndexNav';
	import WNews from '@/views/index/components/IndexNews';
	import WHeader from '@/views/index/components/IndexHeader';
	import WDuty from '@/views/index/components/IndexDuty';
	import WTask from '@/views/index/components/IndexTask';
	import WLove from '@/views/index/components/IndexLove';
	import WCount from '@/views/index/components/IndexCount';
	import WFooter from '@/views/index/components/IndexFooter';
	import WActivity from '@/views/index/components/IndexActivity';
	import IndexSubscribe from '@/views/index/components/IndexSubscribe';
	import Dailysign from '@/views/index/components/Dailysign';
	import DoyinPop from '@/views/index/components/doyinPop';
	import ShopPop from '@/views/index/components/shopPop';
	import UserApi from '@/api/user';
	import Task from '@/api/task';
	import Event from '@/store/event';
	import DistrictApi from '@/api/district.js'
	import DistrictVendor from '@/vendor/district.js'
	import Storage from "@/vendor/storage"
	import ActionVendor from "@/vendor/action"
	import Insurance from '@/views/index/components/insurance';
	

	export default {
		name: 'Index',
		mixins: [scrollMiXin(['.index-scroll'])],
		data() {
			return {
				isShowSubscribe: false,
				isDailysign: false,
				dailysignLove: "",
				isDoyinPop: false,
				isShopPop: false,
				shopPopType: 1,
				ShopPopInfo: '',
				title: '',
				InsuranceShow: true,
			}
		},
		computed: {
			userInfo() {
				return this.$store.state.user.userInfo;
			},
		},
		created() {
			if (this.$route.query.districtId) {
				this.$store.commit('district/setDistrict', {
					id: this.$route.query.districtId,
					name: this.$route.query.districtName,
					type: this.$route.query.districtType
				})
			}
			/**
			 * 完善基本信息弹窗
			 */
			UserApi.getUserInfo()
				.then(result => {
					if (result.data.is_pop == 2) {
						ActionVendor.showRenewalConfirm();
					}
				}, error => {
					console.log(error)
				})

			/**
			 * 是否显示商场浮窗入口
			 */
			UserApi.toast().then(result => {
				if (result.data == '') {
					this.isShopPop = false
					this.shopPopType = 1
					this.ShopPopInfo = ''
				} else {
					this.shopPopType = 2
					this.ShopPopInfo = result.data
				}
			}, error => {
				console.log(error)
			})

			/**
			 * 关注公众号
			 */
			UserApi.isSubscribe().then(result => {
				this.isShowSubscribe = result.data.subscribe == 2
			}, error => {
				console.log('检测失败')
			})

			/**
			 * 关注抖音
			 */
			UserApi.tiktokIsAttention().then(result => {
				this.isDoyinPop = result.data.is_attention == false
			}, error => {
				console.log(error);
			})

			/**
			 * 每日签到
			 */
			Task.hasSigned().then(isSigned => {
				if (isSigned.data.is_signed == 0) {
					Task.SignSignIn().then(result => {
						this.isDailysign = true
						this.dailysignLove = result.data.love_value
					}, error => {
						console.log(error)
					})
				}
			}, error => {
				console.log(error)
			})

			/**
			 * 监听用户关注抖音
			 */
			Event.$on('onDoyinPop', data => {
				this.isDoyinPop = data
			})

			/**
			 * 监听用户信息是否加载完成，检测用户是否属于当前区县，提示用户是否切换
			 */
			Event.$on('onLogin', userInfo => {
				const queryDistrictId = this.$route.query.districtId
				const storageDistrictId = DistrictVendor.getDistrictId()
				const storageDistrictName = DistrictVendor.getDistrictName()

				document.title = storageDistrictName + (storageDistrictId == 1 ? '平安义警协会' : '平安义警分会')
				this.title = storageDistrictName + (storageDistrictId == 1 ? '平安义警协会' : '平安义警分会')
				if (queryDistrictId && queryDistrictId != storageDistrictId) {
					/**
					 * 如果参数区县ID不等与缓存ID并且未跳转过，则跳转至查询ID
					 */
					DistrictApi.getOne(queryDistrictId).then(result => {
						this.toDistrict(result.data.id, result.data.name, result.data.type)
					})
				} else if (userInfo.district_id && userInfo.district_id != storageDistrictId) {
					/**
					 * 如果用户信息有所属区县ID并且用户信息区县ID不等于缓存区县ID，则提示用户是否统一切换
					 */
					VantVendor.Dialog.confirm({
						title: '提示',
						message: `您当前属于${userInfo.district_name}, 是否切换到${userInfo.district_name}`,
					}).then(() => {
						if (userInfo.district_id == 2) {
							window.location.replace('http://xzyjv2.web.zyrlzy.cn/#/');
						} else {
							this.toDistrict(userInfo.district_id, userInfo.district_name, userInfo
								.district_type)
						}
					}, error => {
						console.log('用户拒绝切换')
					})
				}
			})

			this.$store.commit('keep/setKeep', 'Index')
		},
		beforeRouteEnter(to, from, next) {
			next(vm => {
				if (from.name == 'Commodity') {
					const storageDistrictId = DistrictVendor.getDistrictId()
					const storageDistrictName = DistrictVendor.getDistrictName()
					vm.title = storageDistrictName + (storageDistrictId == 1 ? '平安义警协会' : '平安义警分会')
					document.title = vm.title
				}

			})
		},
		destroyed() {
			Event.$off('onLogin')
			Event.$off('onDoyinPop')
		},
		activated() {
			this.$store.commit('keep/removeKeep', 'Commodity');
			this.$store.commit('keep/removeKeep', 'Notice');
			this.$store.commit('keep/removeKeep', 'Purchase');
		},
		methods: {
			onInsuranceConfirm(){
				this.$router.push({name: 'Insurance'})
			},
			toDistrict(districtId, districtName, districtType) {
				this.$store.commit('district/setDistrict', {
					id: districtId,
					name: districtName,
					type: districtType
				})
				window.location.reload()
			},
			onDoyinPopConfirm() {
				this.isDoyinPop = false
				UserApi.tiktokDotNotice().then(result => {
					console.log('取消成功')
				}, error => {
					console.log('取消失败')
				})
			},
			isSubscribe() {
				UserApi.isSubscribe().then(result => {
					if (result.data.subscribe == 2) {
						VantVendor.Toast('您还未关注！')
					} else {
						this.isShowSubscribe = false
					}
				}, error => {
					console.log('检测失败')
				})
			},
			onShopPopConfirm() {
				this.$router.push({
					name: 'Commodity'
				})
				// this.$router.push({
				// 	name: 'CommodityDetail',
				// 	params: {
				// 		id: this.ShopPopInfo.product_id
				// 	},
				// })
			},
			onClickhide() {
				this.shopPopType = 1
			}
		},
		components: {
			WTabbar,
			WHeader,
			WDuty,
			WBest,
			WNav,
			WNews,
			WTask,
			WLove,
			WCount,
			WFooter,
			WActivity,
			ScrollView,
			IndexSubscribe,
			Dailysign,
			DoyinPop,
			ShopPop,
			Insurance
		}
	};
</script>
<!-- bestPeople -->
<!-- bestTeam -->
<!-- bestGroup -->
<style lang="less" scoped>
	.index {
		min-height: calc(100vh - 50px);
		height: calc(100vh - 50px);

		.index-float {
			position: fixed;
			right: 5px;
			bottom: 60px;
			width: 90px;
			height: 135px;
			z-index: 99;

			img {
				width: 100%;
				height: 100%;
				display: flex;
				object-fit: cover;
			}

			.index-float-hide {
				position: fixed;
				right: 10px;
				bottom: 180px;
			}
		}
	}
</style>